import React from 'react';
import SupportRoute from './SupportRoute';
import Presentation from './Presentation';
import './PageInformation.css';

const PageInformation = () => {
  return (
    <div className="App">
      <Presentation />
	  <SupportRoute />
    </div>
  );
};

export default PageInformation;