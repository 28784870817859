import { TextInput, Textarea, Button, Group, Box, NumberInput, Modal } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import React, { useState } from "react";
import './SellForm.css';

export default function SellForm() {
    const [isLoading, setIsLoading] = useState(false);
    
    const form = useForm({
        initialValues: {
          name: '',
          email: '',
          message: '',
          startdate: '',
          core: '',
          storage: '',
        },

        validate: {
            name: (value) => value === ''
              ? 'Please provide name'
              : (/^[\w.@+-]+$/.test(value) ? (value.length < 150 ? null : 'Name must not exceed 150 characters.') : 'Username provided is not valid.'),
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            startdate: (value) => value === '' ? 'Please select start date' : null,
            core: (value) => value === '' ? 'Please provide the number of cores' : null,
            storage: (value) => value === '' ? 'Please provide the storage needed' : null,
        },

    });

    const datemin = new Date()
    const datemin2 = new Date()
    datemin2.setDate(datemin.getDate() + 15);
    
    // create form submit handler:
    const formOnSubmit = form.onSubmit(
        (values) => {
            setIsLoading(true);
            console.log(values)
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values)
            };
            fetch(process.env.REACT_APP_SERVER_URL + "/api/v1/askInformation", requestOptions)
                .then((response) => {
                    setIsModalOpen(true);
                    if (response.ok) {
                      setModalMessage("Email sent successfully!");
                    } else {
                        setModalMessage("Error sending email.");
                    }
                })
                .finally(() => {
                  setIsLoading(false); // Set the isLoading state variable back to false
                });
        },
        // optional, as form.getInputProps() can make inputs display the error themselves:
        (errors) => {
            setIsModalOpen(true);
            setModalMessage("Error sending user.");
            console.error(errors)
        }
      );
      
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

  return (
  <>
    <Box maw={600} mx="auto">
      <form onSubmit={formOnSubmit}>
        <TextInput
          withAsterisk
          label="Name"
          placeholder="Your name"
          {...form.getInputProps('name')}
        />

        <TextInput
          withAsterisk
          label="Email"
          placeholder="Your email"
          {...form.getInputProps('email')}
        />

        <Textarea
          label="Message"
          placeholder="Your message"
          autosize
          minRows={4}
          {...form.getInputProps('message')}
        />

        <DatePickerInput
          label="Select the date for infrastructure delivery"
          withAsterisk
          defaultDate={datemin}
          minDate={datemin}
          maxDate={datemin2}
          {...form.getInputProps('startdate')}
        />

        <NumberInput
          withAsterisk
          label="Select the number of core/h"
          placeholder="Pick one"
          description={
        <>
          To be provided in blocks of 100,000.
        </>
      }
          min={100000}
          max={100000000}
          step={100000}
          precision={0}
          {...form.getInputProps('core')}
        />

        <NumberInput
          withAsterisk
          label="Select the Storage Reservation"
          placeholder="Pick one"
          description={
        <>
          To be provided in TiB.
        </>
      }
          min={1}
          max={10000}
          step={1}
          precision={0}
          {...form.getInputProps('storage')}
        />

        <Group position="center" mt="md">
          <Button className="submitSellFormButton" type="submit" disabled={isLoading} loading={isLoading} >Send</Button>
        </Group>
      </form>
    </Box>
    <Modal
      opened={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      title="Request information"
      size="sm"
    >
      {modalMessage}
      <br/>
      <br/>
        <Button className="SellModalButton" onClick={() => setIsModalOpen(false)}>Close</Button>
    </Modal>
</>

  );
}