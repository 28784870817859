import { TextInput, Textarea, Checkbox, Button, Select, Box, Anchor, PasswordInput, NumberInput, Modal, Grid } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import PhoneInput from 'react-phone-number-input'
import { useForm } from '@mantine/form';
import React, { useEffect, useState } from "react";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import './RegistrationForm.css';
import 'react-phone-number-input/style.css'

export default function RegistrationForm() {
	const [categoryData, setCategoryData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

    const fetchCategoryData = () => {
        return fetch(process.env.REACT_APP_SERVER_URL + "/api/v1/getCategoryList")
              .then((response) => response.json())
              .then((categoryData) => setCategoryData(categoryData));
      }

    useEffect(() => {
		fetchCategoryData();
      },[])
  
    const form = useForm({
        initialValues: {
		  email:'',
          username: '',
		  userpassword: '',
		  country: '',
		  organization: '',
          phonenumber: '',
          contract: '',
          publicsshkey: '',
          termsOfService: false,
          wekeoTermsOfService: false,
		  wekeoPrivacyStatement: false
        },
        validate: {
			email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Email provided is not valid.'),
			username: (value) => value === ''
			  ? 'Please provide username'
			  : (/^[\w.@+-]+$/.test(value) ? (value.length < 150 ? null : 'Username must not exceed 150 characters.') : 'Username provided is not valid.'),
			userpassword: (value) => (/^[A-Za-z0-9!@#$%&*]{5,30}$/.test(value) ? null : 'Password provided is not valid.'),
			country: (value) => value === '' ? 'Please select country' : null,
			organization: (value) => value === '' ? 'Please select organization category' : null,
			phonenumber: (value) => (/^[+].[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(value) ? null : 'Invalid phone number'),
			contract: (value) => value === '' ? 'Please provide the EODC contract number' : null,
			publicsshkey: (value) => value === '' ? 'Please provide public SSH key' : null,
			termsOfService: (value) => value === false && 'Please check this to continue',
			wekeoTermsOfService: (value) => value === false && 'Please check this to continue',
			wekeoPrivacyStatement: (value) => value === false && 'Please check this to continue',			
        },

    });
	
	countries.registerLocale(enLocale);
	const countryObj = countries.getNames("en", { select: "official" });
	const countryData = Object.entries(countryObj).map(([key, value]) => {
		return {
			label: value,
			value: key
		};
	});
    
    // create form submit handler:
	const formOnSubmit = form.onSubmit(
        (values) => {
			setIsLoading(true);
            console.log(values)
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values)
            };
            fetch(process.env.REACT_APP_SERVER_URL + "/api/v1/createUser", requestOptions)
                .then((response) => {
					setIsModalOpen(true);
					if (response.ok) {
					  setModalMessage("User created successfully!");
					} else if (response.status === 409) {
						response.json().then((data) => {
									setModalMessage("The server encoutered an issue during the user creation : "+response.status+" : "+data.error || "Error creating user.");
								});
					} else if (response.status === 404) {
						response.json().then((data) => {
									setModalMessage("The server encoutered an issue during the user creation : "+response.status+" : "+data.error || "Error during account verification.");
								});
				    } else {
						setModalMessage("Error creating user.");
					}
				})
				.finally(() => {
				  setIsLoading(false); // Set the isLoading state variable back to false
				});
        },
        // optional, as form.getInputProps() can make inputs display the error themselves:
        (errors) => {
			setIsModalOpen(true);
			setModalMessage("Error creating user.");
			console.error(errors)
		}
      );
	  
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalMessage, setModalMessage] = useState("");


  return (
  <>
    <Box maw={600} mx="auto">
      <form onSubmit={formOnSubmit}>
        <TextInput
          withAsterisk
          label="Email"
          placeholder="Your email"
          {...form.getInputProps('email')}
        />

        <TextInput
          withAsterisk
          label="Username"
          placeholder="Your username"
          {...form.getInputProps('username')}
        />

        <PasswordInput
          withAsterisk
          label="Password"
          placeholder="Your password"
		  description={
        <>
          Must be 5 to 30 characters long.
          <br/>
            It can contain alphanumeric characters and the following special characters: ! @ # $ % & *
        </>
      }
          {...form.getInputProps('userpassword')}
        />

        <Select
          withAsterisk
          label="Country"
          placeholder="Your country"
		  searchable
		  clearable
		  nothingFound="No options"
          data={countryData}
          {...form.getInputProps('country')}
        />

        <Select
          withAsterisk
          label="Organization category"
          placeholder="Your organization"
		  searchable
		  clearable
		  nothingFound="No options"
          data={categoryData}
          {...form.getInputProps('organization')}
        />

        <Grid grow>
          <Grid.Col span={4}>
             <TextInput
              withAsterisk
              disabled
              label="Phone number"
              placeholder="Your phone number"
              description={
        <>
          Must be in international format.
          <br/>
            Select first your phone country, the enter your national phone number.
        </>
      }
              {...form.getInputProps('phonenumber')}
            />
          </Grid.Col>
          <Grid.Col span={4}>
           <PhoneInput
              withAsterisk
              label="Phone number"
              placeholder="Enter phone number"
              {...form.getInputProps('phonenumber')}
            />
          </Grid.Col>
        </Grid>

        <TextInput
          withAsterisk
          label="EODC contract number"
          placeholder="Your contract number"
          {...form.getInputProps('contract')}
        />

        <Textarea
          placeholder="Your public SSH key"
          label="Public SSH key"
          withAsterisk
          {...form.getInputProps('publicsshkey')}
        />

        <Checkbox
          mt="md"
          label={
        <>
          I accept the{" "}
          <Anchor size="sm" href="https://wekeo.eu/legal" target="_blank">
            WEkEO General Terms and Conditions
          </Anchor>
        </>
      }
          {...form.getInputProps('wekeoTermsOfService', { type: 'checkbox' })}
        />

        <Checkbox
          mt="md"
          label={
        <>
          I accept the{" "}
          <Anchor size="sm" href="https://wekeo.eu/privacy" target="_blank">
            WEkEO Privacy Statement
          </Anchor>
        </>
      }
          {...form.getInputProps('wekeoPrivacyStatement', { type: 'checkbox' })}
        />

        <Checkbox
          mt="md"
          label={
        <>
          I accept the{" "}
          <Anchor size="sm" href="https://eodc.eu/latest_tac" target="_blank">
            EODC terms and conditions
          </Anchor>
        </>
      }
          {...form.getInputProps('termsOfService', { type: 'checkbox' })}
        />
		<Button className="submitRegistrationFormButton" type="submit" disabled={isLoading} loading={isLoading} >Create WEkEO Account</Button>
        
      </form>
    </Box>
	
	<Modal
	  opened={isModalOpen}
	  onClose={() => setIsModalOpen(false)}
	  title="Create User Account"
	  size="sm"
	>
	  {modalMessage}
	  <br/>
	  <br/>
		<Button className="RegistrationModalButton" onClick={() => setIsModalOpen(false)}>Close</Button>
	</Modal>
</>
  );
}