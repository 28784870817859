import React, { Component } from 'react'
import './RegisterRouteButton.css';

class RegisterRouteButton extends Component {

	render() {
	  return (
		<div>
			<div>
				<button className="routeButton" onClick={() => this.props.handleSwitchComponent('Sign')}>
				  Already Have a WEkEO Account
				</button>
			</div>
			<div>
				<button className="routeButton" onClick={() => this.props.handleSwitchComponent('Register')}>
				  Create a WEkEO Account
				</button>
			</div>
		</div>
	  );
	}
}

export default RegisterRouteButton;