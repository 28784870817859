import React, { Component } from 'react';
import './Support.css';

class Route extends Component {

    render() {
      return (
        <div>
            <div>
                <button className="supportButton" onClick={() => this.props.handleSwitchComponent('SellForm')}>
                  Request information
                </button>
            </div>
        </div>
      );
    }
}

export default Route;