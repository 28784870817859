import React, { Component } from 'react'
import './RegisterRoute.css';
import RegistrationForm from './RegistrationForm';
import SignForm from './SignForm';
import RegisterRouteButton from './RegisterRouteButton';

class RegisterRoute extends Component {
	state = { 
	IsSignContainerVisible: false,
	IsHomeContainerVisible: true,
	IsRegisterContainerVisible: false,
		}

handleSwitchComponent = (value) => {
	if (value === 'Register') {
	  this.setState(({ IsRegisterContainerVisible }) => ({
		  IsRegisterContainerVisible: true
		}));
		this.setState(({ IsHomeContainerVisible }) => ({
		  IsHomeContainerVisible: false
		}));
	} else {
	  if (value === 'Sign') {
		  this.setState(({ IsSignContainerVisible }) => ({
			  IsSignContainerVisible: true
			}));
			this.setState(({ IsHomeContainerVisible }) => ({
			  IsHomeContainerVisible: false
			}));
		} else {
		  this.setState(({ IsSignContainerVisible }) => ({
			  IsSignContainerVisible: false
			}));
			this.setState(({ IsHomeContainerVisible }) => ({
			  IsHomeContainerVisible: true
			}));
		}
	}
  };

  render() {
    return (
      <div >
		{this.state.IsHomeContainerVisible && <RegisterRouteButton handleSwitchComponent={this.handleSwitchComponent} />}
		{this.state.IsSignContainerVisible && <SignForm handleSwitchComponent={this.handleSwitchComponent} />}
		{this.state.IsRegisterContainerVisible && <RegistrationForm handleSwitchComponent={this.handleSwitchComponent} />}
      </div>
    );
  }
}

export default RegisterRoute;