import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageInformation from './information/PageInformation.js'
import PageRegister from './register/PageRegister.js'
import NoPage from './NoPage.js'
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PageInformation />}>
        </Route>
        <Route path="/register" element={<PageRegister />}>
        </Route>
		<Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
