import React from 'react';
import Presentation from './Presentation';
import RegisterRoute from './RegisterRoute';
import './PageRegister.css';

const PageRegister = () => {
  return (
    <div className="App">
      <Presentation />
      <RegisterRoute />
    </div>
  );
};

export default PageRegister;
