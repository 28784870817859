import React from 'react';

const PageRegister = () => {
  return (
    <div className="App">
      <div>404 this page does not exists.</div>
    </div>
  );
};

export default PageRegister;
