import './Presentation.css';
import { TypographyStylesProvider } from '@mantine/core';

export default function Presentation() {

    const prestxt = "<br><br><br><br>The EODC High Performance Computing (HPC) Service, offers access to one of Europe’s most powerful processing engines.<br>Whether you're engaged in scientific research, data-intensive simulations, AI/ML modelling, or resource-demanding applications, our HPC offering can be tailored to your most ambitious computational needs.<br>We connect our Petabyte scale EO Storage with several dedicated InfiniBand connections ensuring high-performance doesn’t stop at the compute.<br><br>Key features:<br><li>Access to EODC HPC – A total of 98.560 Cores and 120 NVDIA A100 Graphic Cards.<li>High speed access to the EODC storage, including over 10PB of Copernicus data.<li>Consultancy and support services.<br>Not sure quite what you need?<br>Talk to our experienced engineers – We will help devise the right strategy for your needs!<br><br>More information is available at the following link : <a href='https://docs.eodc.eu/EODC_Cloud/HPC.html'>https://docs.eodc.eu/EODC_Cloud/HPC.html</a>"

  return (
    <TypographyStylesProvider>
      <div dangerouslySetInnerHTML={{ __html: prestxt }} />
    </TypographyStylesProvider>
  );
}
