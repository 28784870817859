import './Presentation.css';
import { TypographyStylesProvider } from '@mantine/core';

export default function Presentation() {

    const prestxt = "<br><br><br><br>Welcome to the registration page for EODC offer.<br><br>Please choose one of the following option.<br><br>EODC support is available at: <a href='mailto:support%40eodc.eu'>support<span>@</span>eodc<span>.</span>eu</a>"

  return (
    <TypographyStylesProvider>
      <div dangerouslySetInnerHTML={{ __html: prestxt }} />
    </TypographyStylesProvider>
  );
}
