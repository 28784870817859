import React, { Component } from 'react'
import './SupportRoute.css';
import SellForm from './SellForm';
import Support from './Support';

class SupportRoute extends Component {
    state = { 
    IsSellContainerVisible: false,
    IsSupportContainerVisible: true,
    }

handleSwitchComponent = (value) => {
    if (value === 'SellForm') {
      this.setState(({ IsSellContainerVisible }) => ({
          IsSellContainerVisible: true
        }));
        this.setState(({ IsSupportContainerVisible }) => ({
          IsSupportContainerVisible: false
        }));
    } else {
      this.setState(({ IsSellContainerVisible }) => ({
          IsSignContainerVisible: false
        }));
        this.setState(({ IsSupportContainerVisible }) => ({
          IsHomeContainerVisible: true
        }));
    }
  };

  render() {
    return (
      <div >
        {this.state.IsSupportContainerVisible && <Support handleSwitchComponent={this.handleSwitchComponent} />}
        {this.state.IsSellContainerVisible && <SellForm handleSwitchComponent={this.handleSwitchComponent} />}
      </div>
    );
  }
}

export default SupportRoute;